import React from 'react';

import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _takeWhile from 'lodash/takeWhile';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { request } from '@travauxlib/shared/src/utils/request';

import { baseUrl } from '../../config';

type Job = {
  id: number;
  status: string;
  conclusion: string;
  name: string;
  completed_at?: string;
  started_at?: string;
  created_at: string;
  steps: {
    name: string;
    conclusion: string;
  }[];
};
type GithubData = {
  workflow: {
    id: number;
    name: string;
    html_url: string;
    conclusion: string;
    actor: {
      avatar_url: string;
    };
  };
  jobs: Job[];
};

const timeWording = (job: Job): string => {
  const { created_at, started_at, completed_at } = job;
  if (completed_at) {
    return `Completed at ${dayjs(completed_at).format('HH:mm')}`;
  }
  if (job.status !== 'queued') {
    return `Started at ${dayjs(started_at).format('HH:mm')}`;
  }
  return `Created at ${dayjs(created_at).format('HH:mm')}`;
};

export const JobComponent: React.FC<{ job: Job }> = ({ job }) => {
  const lastStep = _takeWhile(job.steps, step => step.conclusion !== 'skipped').at(-1);
  return (
    <div
      className={classNames(
        'p-xs border',
        (job.status === 'in_progress' || job.status === 'queued') && 'bg-warning',
        job.conclusion === 'success' && 'bg-success',
        job.conclusion === 'failure' && 'bg-error animate-bounce',
        job.conclusion === 'skipped' && 'bg-info',
      )}
    >
      <div className="flex justify-between items-center">
        <span>{job.name}</span>
        <span className="text-gray-800 text-sm">{timeWording(job)}</span>
      </div>
      <br /> <span className="text-sm">{lastStep?.name}</span>
      {job.conclusion === 'skipped' && ' (skipped)'}
    </div>
  );
};

export const Dev: React.FC = () => {
  const { data, isFetching } = useQuery<GithubData>({
    queryKey: ['GITHUB'],
    queryFn: () => request(`${baseUrl}/data/github`),
    refetchInterval: () => 5000,
    refetchIntervalInBackground: true,
  });
  const queryClient = useQueryClient();
  const debug = false;
  const showSkipped = false;

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (workflowId: number) =>
      request(`${baseUrl}/data/github/workflows/${workflowId}/rerun`, {
        method: 'POST',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GITHUB'] });
    },
  });

  if (!data) {
    return <Loader />;
  }
  const { jobs, workflow } = data;
  const sortedJobs = jobs.slice().sort((jobA, jobB) => {
    if (jobA.conclusion === 'failure' && jobB.conclusion !== 'failure') {
      return -1;
    }
    if (jobA.conclusion !== 'failure' && jobB.conclusion === 'failure') {
      return 1;
    }
    if (jobA.completed_at && jobB.completed_at) {
      return dayjs(jobA.completed_at).isBefore(dayjs(jobB.completed_at)) ? 1 : -1;
    }
    if (jobA.completed_at) {
      return 1;
    }
    if (jobB.completed_at) {
      return -1;
    }
    return dayjs(jobA.created_at).isBefore(dayjs(jobB.created_at)) ? 1 : -1;
  });
  return (
    <div className="m-xl">
      <div className="flex items-center mb-md">
        <img
          className={classNames(
            'w-3xl h-3xl mr-md z-50 relative rounded-full',
            workflow.conclusion === 'failure' && 'animate-ping',
            'hover:animate-spin hover:[animation-duration:0.3s]',
          )}
          src={workflow.actor.avatar_url}
        />
        <h2 className={classNames('mr-md', workflow.conclusion === 'failure' && 'text-error')}>
          <a target="_blank" rel="noopener noreferrer" href={workflow.html_url}>
            {workflow.name}
          </a>
        </h2>
        {isFetching && <Loader size="sm" />}
        {workflow.conclusion === 'failure' && (
          <Button
            disabled={isPending}
            type="button"
            size="sm"
            onClick={() => mutateAsync(workflow.id)}
          >
            Rerun failed
          </Button>
        )}
      </div>
      <div className="w-[600px]">
        {sortedJobs
          .filter(job => showSkipped || job.conclusion !== 'skipped')
          .map(job => (
            <JobComponent key={job.id} job={job} />
          ))}
      </div>
      {debug && <pre>{JSON.stringify(data, null, 4)}</pre>}
    </div>
  );
};
